import Api from '@/services/Index';

const studentForm = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/document/requests/student/form', config);
}

const store = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/document/requests', formData);
}

const getStudentDocuments = async (id, config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/document/requests/student/'+id, config);
}

const cancelRequest = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/document/requests/cancel/'+id);
}

const downloadDocument = async (studentProgramId,id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/document/requests/download/'+studentProgramId+'/'+id, {responseType: 'arraybuffer'});
}

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/document/requests', config);
}

const sendEbys = async (id, note = null) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    Api.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    return Api.post('/document/requests/send/ebys/'+id, { note: note });
}

const updateStatus = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/document/requests/update/status/'+id, formData);
}

const exportExcel = (config)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/document/requests/excel-export',{...config,responseType:'arraybuffer'})
}

const diploma = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/document/requests/diploma', formData);
}

const minorCertificate = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/document/requests/minor-certificate', formData);
}


export default {
    studentForm,
    store,
    getStudentDocuments,
    cancelRequest,
    downloadDocument,
    getAll,
    sendEbys,
    updateStatus,
    exportExcel,
    diploma,
    minorCertificate
}
